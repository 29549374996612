import Swal from "sweetalert2";
import router from "../router";

export default {
  namespaced: true,
  state: {
    consents: {
      loading: false,
      data: [],
    },
    userConsents: {
      loading: false,
      data: [],
    },
    consent: {
      loading: false,
      data: {},
    },
    createConsentLoading: false,
    updateConsentLoading: false,
    deleteConsentLoading: false,
    attachUserConsentLoading: false,
    detachUserConsentLoading: false,
    activateUserConsentLoading: false,
    withdrawUserConsentLoading: false,
  },
  mutations: {
    SET_CONSENTS_LOADING: function (state, payload) {
      state.consents.loading = payload;
    },
    SET_CONSENTS: function (state, payload) {
      state.consents.data = payload;
    },
    SET_CONSENT_LOADING: function (state, payload) {
      state.consent.loading = payload;
    },
    SET_CONSENT: function (state, payload) {
      state.consent.data = payload;
    },
    SET_USER_CONSENTS_LOADING: function (state, payload) {
      state.userConsents.loading = payload;
    },
    SET_USER_CONSENTS_DATA: function (state, payload) {
      state.userConsents.data = payload;
    },
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    getConsents: function ({ commit }) {
      const vm = this;
      commit("SET_CONSENTS_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http.get(`consents`).then(
          (res) => {
            commit("SET_CONSENTS", res.data.data);
            commit("SET_CONSENTS_LOADING", false);
            resolve(res.data);
          },
          (err) => {
            commit("SET_CONSENTS_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    getConsent: function ({ commit }, consentId) {
      const vm = this;
      commit("SET_CONSENT_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http.get(`consents/${consentId}`).then(
          (res) => {
            commit("SET_CONSENT", res.data.data);
            commit("SET_CONSENT_LOADING", false);
            resolve(res.data?.data);
          },
          (err) => {
            commit("SET_CONSENT_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    createConsent: function ({ commit }, payload) {
      commit("SET_STATE", { key: "createConsentLoading", value: true });
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http.post("consents", payload).then(
          () => {
            commit("SET_STATE", { key: "createConsentLoading", value: false });
            Swal.fire({
              title: "Success",
              text: "Consent created successfully",
              icon: "success",
            });
            router.push({ name: "consents" });
            resolve(true);
          },
          (err) => {
            commit("SET_STATE", { key: "createConsentLoading", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    updateConsent: function ({ commit }, payload) {
      let vm = this;
      commit("SET_STATE", { key: "updateConsentLoading", value: payload.id });
      return new Promise(function (resolve) {
        vm.$http.put(`consents/${payload.id}`, payload).then(
          () => {
            commit("SET_STATE", { key: "updateConsentLoading", value: false });
            Swal.fire({
              title: "Success",
              text: "Consent updated successfully",
              icon: "success",
            });
            router.push({ name: "consents" });
            resolve(true);
          },
          (err) => {
            commit("SET_STATE", { key: "updateConsentLoading", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    deleteConsent: function ({ commit }, consentId) {
      let vm = this;
      commit("SET_STATE", { key: "deleteConsentLoading", value: consentId });
      return new Promise((resolve, reject) => {
        vm.$http.delete(`consents/${consentId}`).then(
          () => {
            commit("SET_STATE", { key: "deleteConsentLoading", value: false });
            Swal.fire({
              title: "Success",
              text: "Consent deleted successfully",
              icon: "success",
            });
            resolve(true);
          },
          (err) => {
            commit("SET_STATE", { key: "deleteConsentLoading", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            reject(false);
          }
        );
      });
    },
    getUserConsents: function ({ commit }, payload) {
      const vm = this;
      commit("SET_USER_CONSENTS_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http.get(`users/${payload.userId}/consents`).then(
          (res) => {
            commit("SET_USER_CONSENTS_DATA", res.data.data);
            commit("SET_USER_CONSENTS_LOADING", false);
            resolve(res.data);
          },
          (err) => {
            commit("SET_USER_CONSENTS_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    attachConsentToUser: function ({ commit }, payload) {
      const vm = this;
      commit("SET_STATE", { key: "attachUserConsentLoading", value: true });
      return new Promise((resolve, reject) => {
        vm.$http.post(`users/${payload.user_id}/consents`, payload).then(
          () => {
            commit("SET_STATE", {
              key: "attachUserConsentLoading",
              value: false,
            });
            Swal.fire({
              title: "Success",
              text: "Consent attached to user successfully",
              icon: "success",
            });
            resolve(true);
          },
          (err) => {
            commit("SET_STATE", {
              key: "attachUserConsentLoading",
              value: false,
            });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            reject(false);
          }
        );
      });
    },
    detachConsentFromUser: function ({ commit }, payload) {
      const vm = this;
      commit("SET_STATE", {
        key: "detachUserConsentLoading",
        value: payload.userConsentId,
      });
      return new Promise((resolve, reject) => {
        vm.$http
          .delete(`users/${payload.userId}/consents/${payload.userConsentId}`)
          .then(
            () => {
              commit("SET_STATE", {
                key: "detachUserConsentLoading",
                value: false,
              });
              Swal.fire({
                title: "Success",
                text: "Consent detached from user successfully",
                icon: "success",
              });
              resolve(true);
            },
            (err) => {
              commit("SET_STATE", {
                key: "detachUserConsentLoading",
                value: false,
              });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    err?.data?.error?.message ||
                    err?.data?.message ||
                    "Something went wrong...",
                  icon: "error",
                });
              }
              reject(false);
            }
          );
      });
    },
    activateConsentForUser: function ({ commit }, payload) {
      const vm = this;
      commit("SET_STATE", {
        key: "activateUserConsentLoading",
        value: payload.userConsentId,
      });
      return new Promise((resolve, reject) => {
        vm.$http
          .put(
            `users/${payload.userId}/consents/${payload.userConsentId}/activate`
          )
          .then(
            () => {
              commit("SET_STATE", {
                key: "activateUserConsentLoading",
                value: false,
              });
              Swal.fire({
                title: "Success",
                text: "The consent has been activated successfully",
                icon: "success",
              });
              resolve(true);
            },
            (err) => {
              commit("SET_STATE", {
                key: "activateUserConsentLoading",
                value: false,
              });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    err?.data?.error?.message ||
                    err?.data?.message ||
                    "Something went wrong...",
                  icon: "error",
                });
              }
              reject(false);
            }
          );
      });
    },
    withdrawConsentForUser: function ({ commit }, payload) {
      const vm = this;
      commit("SET_STATE", {
        key: "withdrawUserConsentLoading",
        value: payload.userConsentId,
      });
      return new Promise((resolve, reject) => {
        vm.$http
          .put(
            `users/${payload.userId}/consents/${payload.userConsentId}/withdraw`
          )
          .then(
            () => {
              commit("SET_STATE", {
                key: "withdrawUserConsentLoading",
                value: false,
              });
              Swal.fire({
                title: "Success",
                text: "The consent has been withdrawn successfully",
                icon: "success",
              });
              resolve(true);
            },
            (err) => {
              commit("SET_STATE", {
                key: "withdrawUserConsentLoading",
                value: false,
              });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    err?.data?.error?.message ||
                    err?.data?.message ||
                    "Something went wrong...",
                  icon: "error",
                });
              }
              reject(false);
            }
          );
      });
    },
  },
};

import Swal from "sweetalert2";
import { remove, map } from "lodash";
import router from "../router/index";

export default {
  namespaced: true,
  state: {
    data: {},
    isLoading: false,
    isSaving: false,
    creditCardDeleteLoading: null,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    storeCard: function (context, data) {
      if (!data) return;
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .post(
            `payments/cards${data.user_id ? "?user_id=" + data.user_id : ""}`,
            {
              payment_method_id: data.payment_method_id,
            }
          )
          .then(
            (res) => {
              if (res) {
                Swal.fire({
                  title: "Saved successfully",
                  text: "Credit card added successfully",
                  icon: "success",
                });
                router.push({
                  name: "creditCards",
                  params: { id: data.patient_id },
                });
              }
              resolve(res);
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Oops!",
                  text:
                    err?.data?.error?.message ||
                    err?.data?.message ||
                    "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
    getCreditCards: function (context, user_id) {
      context.commit("SET_STATE", {
        key: "isLoading",
        value: true,
      });
      this.$http
        .get(`payments/cards${user_id ? "?user_id=" + user_id : ""}`)
        .then((res) => {
          context.commit("SET_STATE", {
            key: "data",
            value: res.data.data,
          });
          context.commit("SET_STATE", {
            key: "isLoading",
            value: false,
          });
        })
        .catch((err) => {
          Swal.fire("Error", err.data?.error?.message, "error");
        });
    },
    removeCreditCard: function ({ state, commit }, payload) {
      commit("SET_STATE", {
        key: "creditCardDeleteLoading",
        value: payload?.id,
      });
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http.delete(`payments/cards/${payload.id}`).then(
          (res) => {
            if (res.status == 200) {
              state.data = remove(state.data, function (n) {
                return n.id != payload.id;
              });

              Swal.fire({
                title: "Deleted successfully",
                text: "Credit card deleted successfully",
                icon: "success",
              });
            }
            commit("SET_STATE", {
              key: "creditCardDeleteLoading",
              value: null,
            });
            resolve(res);
          },
          (err) => {
            commit("SET_STATE", {
              key: "creditCardDeleteLoading",
              value: null,
            });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    setCreditCardDefault: function (context, data) {
      this.$http
        .put(`payments/cards/${data.id}`, { is_default: true })
        .then((res) => {
          if (res.status == 200) {
            context.state.data = map(context.state.data, function (n) {
              if (n.id === data.id) {
                return { ...n, is_default: true };
              } else {
                return { ...n, is_default: false };
              }
            });
          }
        })
        .catch((err) => {
          Swal.fire("Error", err.data?.error?.message, "error");
        });
    },
  },
};
